import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { createFontProperties } from "@utils/theme-utils";

const blue = {
    light: "#9fa8da",
    main: "#3f51b5",
    dark: "#1a237e",
    contrastText: "#8c9eff",
};

const secondary = {
    light: "rgba(0, 0, 0, 0.6)",
    main: "#3e4652",
    dark: "#2B313A",
    contrastText: "#ffffff",
};

const grey = {
    light: "#b6bdc8",
    main: "#828ea0",
    dark: "#5f6c7f",
    contrastText: "#ffffff",
};

const error = {
    main: "#fe0404",
    contrastText: "#fff",
};

let theme = {
    palette: {
        primary: blue,
        secondary: secondary,
        blue: blue,
        grey: grey,
        blueGrey: "#3e4652",
        white: "#fff",
        error: error,
        text: {
            primary: secondary.main,
            secondary: blue.main,
            disabled: "rgba(0, 0, 0, 0.38)",
        },
        background: {
            default: "#fff",
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
            },
        },
        MuiPaper: {
            elevation4: {
                boxShadow: "0 30px 160px 0 rgba(0, 0, 0, 0.15)",
            },
        },
        MuiChip: {
            label: {
                overflow: "visible",
            },
        },
    },
    typography: {
        fontFamily: "Montserrat, Poppins",
        h1: createFontProperties({
            family: "Montserrat",
            weight: 700,
            size: "94.3px",
            letterSpacing: "-1.5px",
        }),
        h2: createFontProperties({
            family: "Montserrat",
            weight: 500,
            size: "59.8px",
            letterSpacing: "-0.5px",
        }),
        h3: createFontProperties({
            family: "Montserrat",
            weight: 400,
            size: "48.3px",
        }),
        h4: createFontProperties({
            family: "Montserrat",
            weight: 700,
            size: "33.4px",
            letterSpacing: "0.25px",
        }),
        h5: createFontProperties({
            family: "Montserrat",
            weight: 600, //TODO: Talk to Zanna about adjusting
            size: "23.9px",
        }),
        h6: createFontProperties({
            family: "Montserrat",
            weight: 400,
            size: "20.1px",
            letterSpacing: "0.25px",
        }),
        subtitle1: createFontProperties({
            family: "Montserrat",
            weight: 600,
            size: "15.8px",
            letterSpacing: "0.15px",
        }),
        subtitle2: createFontProperties({
            family: "Montserrat",
            weight: 600,
            size: "13.9px",
            letterSpacing: "0.1px",
        }),
        body1: createFontProperties({
            family: "Poppins",
            weight: 400,
            size: "15.5px",
            lineHeight: 1.81,
            letterSpacing: "0.5px",
        }),
        body2: createFontProperties({
            family: "Poppins",
            weight: 400,
            size: "13.5px",
            lineHeight: 1.2,
            letterSpacing: "0.25px",
        }),
        button: createFontProperties({
            family: "Poppins",
            weight: 800,
            size: "16px",
            lineHeight: 1.21,
            letterSpacing: "2.71px",
        }),
        caption: createFontProperties({
            family: "Montserrat",
            weight: 400,
            size: "12.1px",
            lineHeight: 1.32,
            letterSpacing: "0.4px",
        }),
        overline: createFontProperties({
            family: "Montserrat",
            weight: 400,
            size: "12.1px",
            lineHeight: 1.32,
            letterSpacing: "2px",
        }),
    },
    spacing: 8,
};

theme = createTheme(theme);
theme = responsiveFontSizes(theme);
export default theme;