/**
 * Campaign Partner Pixels Configuration module
 * @module apps/multi-step-app/brands/dan/pixel-config.js
 *
 * Structure:
 *      The pixel config contains pixels data for each specific campaign partner. To add a
 *      new campaign partner pixel to this config, the structure is as follows:
 *
 *          [campaignId]: {
 *              // default settings applied to all lifecycle events for this campaign can be added at this level
 *              lifecycle: {
 *                  onEntry: {
 *                      // settings for lifecycle-specfic events can be added here
 *                      position: "head" | "body",
 *                      entriesList: [
 *                          {
 *                              position: "head" | "body",
 *                              code: string | function,
 *                          },
 *                      ],
 *                  },
 *                  onRouteChange: {
 *                      entriesList: [
 *                          action: () => {},
 *                      ],
 *                  },
 *                  postSubmit: {
 *                      // settings for lifecycle-specfic events can be added here
 *                      position: "head" | "body",
 *                      entriesList: [
 *                          {
 *                              code: string | function,
 *                              hasDocumentWriteFn: boolean,
 *                              postback: object | boolean,
 *                          },
 *                      ],
 *                  }
 *                  [some_lifecycle_event]: object | boolean,
 *              },
 *          }
 *
 * Property values:
 *      campaignId {number}             - the campaign id associated with the campaign partner.
 *                                        `campaignId: 0` is a global entry, for pixels that should be loaded regardless of
 *                                        campaign (i.e Facebook Pixel, Snapchat Pixel, Twitter Pixel, etc).
 *
 *      lifecycle {object}              - contains the lifecycle events the pixel can have
 *
 *      onEntry {boolean || object}     - tells the CampaignHandler whether or not a pixel needs to be
 *                                        injected at the beginning of the app. If a pixel is NOT required,
 *                                        then a value of `false` will suffice. If a pixel IS required to
 *                                        be injected, then include in the `onEntry` object a `code`
 *                                        property that defines the html pixel to be injected.
 *
 *      onRouteChange {object}          - a lifecycle event for route changes in the app, execute some action (i.e call a function)
 *
 *      postSubmit {boolean || object}  - similar to the onEntry event, this tells the CampaignHandler whether or not to
 *                                        fire some sort of post-submit logic or inject some code into the DOM. The shape
 *                                        and values of this object is also similar to onEntry, where a `code` and `position`
 *                                        property can also be defined.
 *
 *      entriesList {array}        - a list of pixel entries where each entry represents a pixel code and postback if applicable
 *
 *      position {string}               - the position of where the pixel should be injected into the DOM.
 *
 *      code {string || function}       - defines the html pixel code to be injected into the DOM. This property's
 *                                        value can be a template literal (string) for static html snippets or a
 *                                        function for dynamic html snippets.
 *
 *      hasDocumentWriteFn {boolean}    - found/defined within a lifecycle event. This is to guard against unexpected behaviours
 *                                        if 'document.write' is known to be executed. If 'true', 'document.write' is replaced
 *                                        temporarily before being restored again after its invocation.
 *
 *      postback {boolean || object}    - a property to indicate that a request is required to be sent to some partner API for
 *                                        postback purposes, usually to approve/decline transactions. Within this property, there
 *                                        contains an `apiUrl` and `payload` properties that's for the target partner API configuration.
 *
 *      url {string}                    - the postback API url
 *
 *      payload {object || function}    - similar to the `code` property, this defines the payload for the postback request.
 *
 *      httpMethod {string}             - the http method for the postback request
 *
 *      headers {object}                - the headers for the postback request
 *
 *      * In any of the lifecycle events where code is needed to be injected (i.e onEntry, postSubmit), the default
 *        `position` property can be overridden by defining a `position` property within the lifecycle event itself.
 *        If a position is defined within the lifecycle event, that position will be used upon injection. Otherwise,
 *        the default position defined will be used.
 *
 * Method signatures:
 *      code(_, <param_to_use>) => string | payload(_, <param_to_use>) => object
 *          * the stage/event name {string} is passed into first argument
 *          * the campaignData fetched from the app is passed into the second argument of this
 *            method so that any property within campaignData can be used for a dynamic pixel snippet.
 *
 * Caveats:
 *      `code` property:
 *          * CSP (Content Security Policy) - New pixels being added and used in the config need to be verified in a
 *                                            production build run. CSP policies can cause the pixels to fail, thus it
 *                                            is important that the source domains/subdomains of the pixels are being added
 *                                            to the CSP directives in `gatsby-config.js` under the CLC Loan Application
 *                                            (path: /apps/clc-loan-application).
 *
 *          * Code retrieved from the `code` property MUST be valid HTML or the injection will fail.
 *
 *          * The code defined must be simple html. Since the html code is being added into the DOM via `innerHTML`, this
 *            means any embedded tags inside another tag will not be executed. i.e For tags such as <script>, they must be
 *            top-level in order for it to be fired properly.
 */

export default {
    0: { // global
        lifecycle: {
            onEntry: {
                position: "head",
                entriesList: [
                    { // Facebook Pixel
                        position: "body",
                        code: `
                            <script type="text/javascript">
                                !function(f,b,e,v,n,t,s)
                                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                n.queue=[];t=b.createElement(e);t.async=!0;
                                t.src=v;s=b.getElementsByTagName(e)[0];
                                s.parentNode.insertBefore(t,s)}(window, document,'script',
                                'https://connect.facebook.net/en_US/fbevents.js');
                                fbq('init', '649739105625403');
                            </script>
                            <noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=649739105625403&ev=PageView&noscript=1"/></noscript>
                        `,
                    },
                    { // Snapchat Pixel
                        code: `
                            <script type="text/javascript">
                                (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                                {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                                a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                                r.src=n;var u=t.getElementsByTagName(s)[0];
                                u.parentNode.insertBefore(r,u);})(window,document,
                                'https://sc-static.net/scevent.min.js');                                
                                snaptr('init', '408202b8-8b63-4d7f-8083-e3d0b76acf9d', { user_email: undefined });                 
                            </script>
                        `,
                    },
                    { // Twitter Pixel
                        code: `
                            <script type="text/javascript">
                                !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
                                },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
                                a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
                                // Insert Twitter Pixel ID and Standard Event data below
                                twq('init','o4cnq');                                
                            </script>
                        `,
                    },
                ],
            },
            onRouteChange: {
                entriesList: [
                    { // Facebook Pixel
                        action: () => {
                            if (window.fbq) window.fbq("track", "PageView");
                        },
                    },
                    { // Snapchat Pixel
                        action: () => {
                            if (window.snaptr) window.snaptr("track", "PAGE_VIEW");
                        },
                    },
                    { // Twitter Pixel
                        action: () => {
                            if (window.twq) window.twq("track", "PageView");
                        },
                    },
                ],
            },
            postSubmit: {
                position: "body",
                entriesList: [
                    { // Snapchat Pixel
                        action: () => {
                            if (window.snaptr) window.snaptr("track", "SIGN_UP");
                        },
                    },
                ],
            },
        },
    },

};