module.exports = {
    brandInfo: {
        phoneNumber: "18885364210",
        contactEmail: "info@dailyauto.news",
        copyright: `© ${new Date().getFullYear()} Daily Auto News`,
        defaultCampaignId: "77",
    },

    images: {
        icon: "images/Daily_Auto_News.png",
    },

    project: "dan-loan-application",
    name: "Daily Auto News",
    shortName: `DAN`,
    description: `Stay updated with the Automotive Industry.`,

    siteUrl: "https://application.dailyauto.news",
    homeUrl: "/",
    termsUrl: "https://www.dailyauto.news/terms-of-service",
    privacyPolicyUrl: "https://www.dailyauto.news/privacy-policy",
    successUrl: "https://www.dailyauto.news/success",

    hideSuccessCtas: true,
};